// Utils
import { LandingPageInternalPath } from "utils/paths";
export var testimonials = [
    {
        name: "Bryan Ackermann",
        avatar: "/assets/testimonials/brian_ackermann.webp",
        jobTitle: "Head of AI Strategy, Korn Ferry",
        text: '"The future of coaching is here. All Korn Ferry customers can now get get self paced, judgement-free communication coaching, powered by Yoodli. The perfect augmentation to human coaching."'
    },
    {
        name: "Dave Wright",
        avatar: "/assets/testimonials/dave_wright.webp",
        jobTitle: "CEO, Dale Carnegie Texas",
        text: '"We’ve built Dale Carnegie Texas AI, powered by Yoodli - my jaw dropped the moment I saw the first demo. Our learners, fellow franchises, and customers have the same reaction. It’s the best way to reinforce live training."'
    },
    {
        name: "Ashraf Mathkour",
        avatar: "/assets/testimonials/ashraf_mathkour.webp",
        jobTitle: "Head of Mid Market Sales, Uber",
        text: '"Yoodli is revolutionizing learning with scalable, personalized roleplays with applications across the enterprise —something that wasn’t possible before generative AI. Long live DATA!"'
    },
    {
        name: "Craig Valentine",
        avatar: "/assets/testimonials/craig_valentine.webp",
        jobTitle: "World Champion of Public Speaking",
        text: '"Yoodli enables pitch coaching at scale. It’s customizable and took just minutes to set up. I use it with every student, trainer, and World Championship contestant I train."'
    },
    {
        name: "Kirstin Parker",
        avatar: "/assets/testimonials/kristin_parker.webp",
        jobTitle: "Executive Communications Director, Accenture",
        text: '"I work with Fortune 50 execs - Yoodli is my speech coaching assistant. It reinforces my feedback with quantifiable data. Think of Yoodli as the thermometer if I’m the doctor."'
    },
    {
        name: "Jon Bischke",
        avatar: "/assets/testimonials/jon_bischke.webp",
        jobTitle: "CEO of Ascribe,  former Udemy Board Member",
        text: '"As a leader, communication is an extremely important part of my job. However, it’s tough to get real-time feedback. Yoodli feels like a superpower -  I can improve with each and every interaction I have."'
    },
    {
        name: "Nicole Kayla",
        avatar: "/assets/testimonials/nicole_kayla.webp",
        jobTitle: "Learning & Development, Google Tech",
        text: '"We’ve never seen this kind of interest for a soft skills training program—I hear about Yoodli at least once a day from another Googler. Over 400 people attended our first Yoodli webinar!"'
    }
];
// TODO 2024-05-23: Uncomment when we've gotten approval to use
// {
//   name: "Jason Baumgarten",
//   avatar: "/assets/testimonials/jason_baumgarten.webp",
//   jobTitle: "Global Head, CEO & Board Practice, Spencer Stuart",
//   text: '"Partnering with Yoodli allows us to give our clients an even more personalized coaching experience. Any mis-step during a high stakes interview for a leadership position can have massive consequences. Yoodli allows candidates to build their confidence without feeling judged by anyone else."',
// },
export var howItWorks = [
    {
        title: "Choose your scenario",
        body: "Customize your roleplay partner and speaking goals",
        image: "/assets/yoodli-HIW2.webp"
    },
    {
        title: "Start speaking",
        body: "Yoodli will ask you AI-powered follow-up questions",
        image: "/assets/yoodli-HIW3.webp"
    },
    {
        title: "View your results",
        body: "Get real-time feedback on your content, delivery, and progress over time",
        image: "/assets/yoodli-HIW4.webp"
    }
];
export var howWillYouUseYoodli = [
    {
        title: "GTM Enablement",
        bulletPoints: [
            "Upload your custom content, best practices, methodology",
            "Ramp and certify reps with near-realistic roleplays",
            "Quantify performance, export reports, and track progress over time"
        ],
        cta: {
            copy: "Learn more",
            linkTo: LandingPageInternalPath.FOR_BUSINESS_SALES
        },
        image: "/assets/graphic_main_salesenablement.webp"
    },
    {
        title: "Learning & Development",
        bulletPoints: [
            "Set benchmarks with quantifiable speaking analytics such as pacing, conciseness, and sentence starters",
            "Customize scenarios for roleplay practice",
            "Track progress over time, in aggregate, across cohorts"
        ],
        cta: {
            copy: "Learn more",
            linkTo: LandingPageInternalPath.FOR_BUSINESS_LEARNING_AND_DEVELOPMENT
        },
        image: "/assets/graphic_main_managertraining.webp"
    },
    {
        title: "Partner Enablement",
        bulletPoints: [
            "Upload your custom content and certification rubric ",
            "Simulate quizzes and credentialing through near-realistic roleplays",
            "Quantify performance, export reports, and track progress over time"
        ],
        cta: {
            copy: "Learn more",
            linkTo: LandingPageInternalPath.FOR_BUSINESS_CUSTOMER_SUCCESS
        },
        image: "/assets/graphic_main_coaching_companies.webp"
    },
    {
        title: "Corporate Communications",
        bulletPoints: [
            "Upload your brand guide, talking points, and custom content",
            "Simulate realistic AI roleplays (media training, exec comms)",
            "Track your progress over time"
        ],
        cta: {
            copy: "Learn more",
            linkTo: LandingPageInternalPath.FOR_BUSINESS_CORPORATE_COMMUNICATIONS
        },
        image: "/assets/graphic_main_corpcomms.webp"
    }
];
export var whyYoodli = [
    {
        title: "Private & secure",
        body: "Your speeches are only visible to you and private by default"
    },
    {
        title: "Quantify your progress over time",
        body: "Track the metrics you care about - conciseness, filler words, pacing and more"
    },
    {
        title: "Built by experts",
        body: "Built by world champions of public speaking, TED speakers, and thousands of speech coaches"
    }
];
export var useCases = [
    {
        title: "Job interview",
        image: "/assets/job-interview.png",
        href: "/usecases/interview-preparation"
    },
    {
        title: "Public speaking",
        image: "/assets/public-speaking.png",
        href: "/usecases/public-speaking"
    },
    {
        title: "Online meetings",
        image: "/assets/everyday-convo.png",
        href: "/usecases/online-meetings"
    }
];
