function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
// Components
// Utils
import { ReferralProgram } from "lib-fullstack/utils/referralProgramUtils";
var _obj;
var metadataConfig = (_obj = {
    // index = main landing page
    index: {
        title: "Yoodli | AI Roleplays",
        description: "Ace your next pitch, speech or interview with private, realtime coaching. Used by Google, Sandler Sales, RingCentral, Korn Ferry, and more."
    },
    dpa: {
        title: "Yoodli | Data Processing Addendum",
        description: "Yoodli is committed to protecting your privacy. This Data Processing Addendum outlines how we collect, use, and protect your personal information."
    },
    community: {
        title: "Yoodli | AI Roleplays",
        description: "Improve your confidence with personalized communication coaching from Yoodli. Get feedback on your speaking skills without the pressure of an audience."
    },
    404: {
        title: "Page Not Found | Yoodli",
        description: "Uh oh. We should have been more prepared for this speech! Navigate to www.yoodli.ai so you’re not in the same situation"
    },
    500: {
        title: "Internal Server Error | Yoodli",
        description: "Uh oh. We should have been more prepared for this speech! Navigate to www.yoodli.ai so you’re not in the same situation"
    },
    glossary: {
        title: "Glossary | Yoodli",
        description: "Curious about what a word or concept means? Take a look in the glossary to learn more about everything Yoodli related!"
    },
    "sample-speeches": {
        title: "Sample Speeches | Yoodli",
        description: "Take a look at these recommended sample speeches!"
    },
    "coach-directory": {
        title: "Coach Directory | Yoodli",
        description: "Looking for a coach to help you take your speaking to the next level? Find them here on our Yoodli Coach Directory!"
    },
    "coach-directory/application": {
        title: "Coach Directory Application | Yoodli",
        description: "Apply to join the Yoodli Coach Directory today!"
    },
    blog: {
        title: "Blog | Yoodli",
        description: "The Yoodli blog has speech coaching tips to help you improve your communication skills and sound confident, anytime you speak"
    },
    "feature-announcements": {
        title: "Feature Announcements | Yoodli",
        description: "Stay updated with the latest features and improvements at Yoodli."
    },
    webinars: {
        title: "Webinars | Yoodli",
        description: "Learn how Yoodli has helped people across every industry improve their communication skills and sound confident, anytime they speak."
    },
    "our-partnerships": {
        title: "Our Partnerships | Yoodli",
        description: "Learn how Yoodli has helped people across every industry improve their communication skills and sound confident, anytime they speak."
    },
    "talk-to-sales": {
        title: "Talk to Sales | Yoodli",
        description: "Get in touch with our sales team to learn more about how Yoodli can help your organization."
    },
    pricing: {
        title: "Pricing | Yoodli",
        description: "Find all the information you need about pricing on Yoodli"
    },
    ambassadors: {
        title: "Ambassadors | Yoodli",
        description: "Become a Yoodli Ambassador and earn rewards today!"
    },
    copyright: {
        title: "Copyright | Yoodli",
        description: "Yoodli takes claims of copyright infringement seriously. Learn more about our policy here."
    },
    press: {
        title: "Press & Media | Yoodli",
        description: "Press Page Description"
    },
    about: {
        title: "About Us | Yoodli",
        description: "At Yoodli, we're helping people improve their public speaking and communication skills without the fear of judgment."
    },
    faq: {
        title: "FAQ | Yoodli",
        description: "Have questions about Yoodli? We have answers! Check out our FAQ page to learn more about Yoodli and how it works."
    },
    privacy: {
        title: "Privacy Policy | Yoodli",
        description: "You control who can access your speeches on Yoodli. We put security first and do not sell your information to third parties."
    },
    tos: {
        title: "Terms of Service | Yoodli",
        description: "Our Terms of Service govern your use of Yoodli website and associated tools made available by Yoodli, Inc."
    },
    "use-cases/interview-preparation": {
        title: "Interview Prep | Yoodli",
        description: "Enhance your interview skills with Yoodli's AI speech coach. Practice and refine your answers to ace your next job interview with confidence."
    },
    "use-cases/crucial-conversations": {
        title: "Conversations | Yoodli",
        description: "Navigate critical discussions with ease using Yoodli. Our AI coach helps you communicate confidently and effectively in high-stakes situations."
    },
    "use-cases/public-speaking": {
        title: "Public Speaking | Yoodli",
        description: "Master the art of public speaking with Yoodli. Gain insights into your performance and improve your delivery, engagement, and presence on stage."
    },
    "use-cases/online-meetings": {
        title: "Online Meetings | Yoodli",
        description: "Make a lasting impression in virtual meetings with live AI coaching. Improve conciseness, and have an impact in all your online communications."
    },
    "use-cases/toastmasters": {
        title: "Toastmasters | Yoodli",
        description: "Toastmasters can elevate their speaking skills with Yoodli. Get personalized feedback and track progress to excel in your Toastmasters journey."
    },
    "use-cases/speech-coaches": {
        title: "Speech Coaches | Yoodli",
        description: "Empower your clients with Yoodli's cutting-edge AI speech analysis. Enhance your coaching with detailed metrics and actionable insights."
    },
    "for-business/corporate-communications": {
        title: "Corporate Comms | Yoodli",
        description: "Strengthen your company's internal communication with Yoodli. Drive engagement and clarity across all levels of your organization."
    },
    "for-business/learning-and-development": {
        title: "Learning and Development | Yoodli",
        description: "Revolutionize your L&D programs with Yoodli. Foster effective communication skills that accelerate personal and professional growth."
    },
    "for-business/customer-success": {
        title: "Customer Success | Yoodli",
        description: "Empower your customer success team with Yoodli. Enhance communication skills to build trust, resolve issues, and drive customer satisfaction."
    },
    "for-business/leadership": {
        title: "Leadership | Yoodli",
        description: "Build influential leaders with Yoodli's AI speech coaching. Enhance decision-making, persuasion, and public speaking skills for leadership roles."
    },
    "for-business/engineering": {
        title: "Engineering | Yoodli",
        description: "Improve communication within technical teams using Yoodli. Our AI coach helps engineers convey complex ideas clearly and effectively."
    },
    "for-business/sales": {
        title: "Sales | Yoodli",
        description: "Boost your sales team's performance with Yoodli. Refine pitching, negotiation, and relationship-building skills to close more deals."
    },
    courses: {
        title: "Courses | Yoodli",
        description: "Level up your speech game and learn from world-class speech and communication coaches"
    }
}, _define_property(_obj, ReferralProgram.MICROSOFT_FOR_STARTUPS, {
    title: "Microsoft For Startups | Yoodli",
    description: "Yoodli is an AI powered communication coach that helps you ace your next interview, speech, or everyday conversation."
}), _define_property(_obj, ReferralProgram.COURSERA, {
    title: "Coursera | Yoodli",
    description: "Yoodli is an AI powered communication coach that helps you ace your next interview, speech, or everyday conversation."
}), _define_property(_obj, ReferralProgram.INDEED, {
    title: "Indeed | Yoodli",
    description: "Yoodli is an AI powered communication coach that helps you ace your next interview, speech, or everyday conversation."
}), _define_property(_obj, ReferralProgram.SPENCER_STUART, {
    title: "Spencer Stuart | Yoodli",
    description: "Yoodli is an AI powered communication coach that helps you ace your next interview, speech, or everyday conversation."
}), _define_property(_obj, ReferralProgram.PROTOCOMMS, {
    title: "Protocomms | Yoodli",
    description: "Yoodli is an AI powered communication coach that helps you ace your next interview, speech, or everyday conversation."
}), _obj);
export function getMetaConfigExists(page) {
    return metadataConfig[page] !== undefined;
}
export default function getMetaConfig(page, field) {
    if (!metadataConfig[page]) {
        return ({
            title: "Yoodli",
            description: "Ace your next pitch, speech or interview with private, realtime coaching. Used by Google, Sandler Sales, RingCentral, Korn Ferry, and more."
        })[field];
    }
    return metadataConfig[page][field];
}
